<template>
  <div class="service-grid-item service-grid-item--style2">
    <div class="service-grid-item__image">
      <div class="service-grid-item__image-wrapper">
        <img
          :src="project.image"
          style="width: -webkit-fill-available;"
          class="img-fluid"
          alt="thumbnail"
        />
      </div>
    </div>
    <div class="service-grid-item__content" id="content-box" :class="[height]">
      <h3 class="title text-center">
        {{ project.title }}
      </h3>
      <p class="subtitle" style="text-align:justify;">{{ project.desc }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["project", "height"]
};
</script>

<style lang="scss">
.height280 {
  height: 280px;
}
.height200 {
  height: 200px;
}
.height90 {
  height: 90px;
}
.height220 {
  height: 220px;
}
</style>