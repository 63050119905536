<template>
  <!--====================  video cta area ====================-->
  <div class="video-cta-area section-space--inner--60">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <div class="video-cta-content">
            <h2 style="padding-bottom: 20px;">{{ title }}</h2>
            <p style="text-align: justify;">
              {{ data.shortDesc }}
            </p>
            <p style="text-align: justify;">
              {{ data.desc }}
            </p>
            <p style="text-align: justify;">
              {{ data.desc2 }}
            </p>

            <a :href="data.bLink"
              ><button
                style="text-transform: initial;"
                class="btn ht-btn--round"
              >
                {{ data.bText }}
              </button></a
            >
          </div>
        </div>
        <div class="col-lg-5 offset-lg-1 col-md-12">
          <div class="cta-video-image">
            <div class="video-popup">
              <silentbox-single :src="data.youtubeLink">
                <div class="cta-video-image__image">
                  <img :src="data.image" class="img-fluid" alt="image" />
                </div>
                <div class="cta-video-image__icon">
                  <i class="ion-ios-play"></i>
                </div>
              </silentbox-single>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--====================  End of video cta area  ====================-->
</template>

<script>
export default {
  props: ["data", "title"],
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.ht-btn--round {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: -10%;
  font-size: 20px;
  position: relative;
  text-align: center;
  margin: auto;
  display: inline-flex;
  align-items: center;
  vertical-align: bottom !important;
  background-color: #e94e1b;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 18px;
}
</style>