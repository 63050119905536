<template>
  <!--====================  project slider area ====================-->
  <swiper :options="swiperOption">
    <div
      class="swiper-slide latest-project-slider__single-slide"
      v-for="slider in sliders"
      :key="slider.id"
    >
      <div class="image">
        <img
          style="width: 100%;padding: 0px;"
          :src="slider.image"
          class="img-fluid"
          alt="thumbnail"
        />
      </div>
    </div>
  </swiper>
  <!--====================  End of project slider area  ====================-->
</template>

<script>
export default {
  props: ["sliders"],
  data() {
    return {
      swiperOption: {
        speed: 1000,
        loop: true,
        effect: "fade",
        slideChange: 10000,
        delay: 5000,
        autoplay: true,
        slidesPerView: 1,
        spaceBetween: 0
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.swiper-container {
  padding-top: 0px;
}
</style>