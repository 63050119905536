<template>
  <!--====================  project slider area ====================-->
  <div class="project-slider-area" style="padding-top: 10px;">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="latest-project-slider">
            <div class="latest-project-slider__container-area">
              <div class="swiper-container latest-project-slider__container">
                <div class="swiper-wrapper latest-project-slider__wrapper">
                  <swiper :options="swiperOption">
                    <div
                      class="swiper-slide latest-project-slider__single-slide"
                      v-for="conoce in payforu.conoceMas"
                      :key="conoce.id"
                    >
                      <div class="row row-30 align-items-center">
                        <div class="col-lg-6">
                          <div class="image">
                            <img
                              :src="conoce.image"
                              class="img-fluid"
                              alt="thumbnail"
                            />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="content">
                            <h2 class="title" style="font-size: 25px;">
                              {{ conoce.title }}
                            </h2>
                            <p class="desc">{{ conoce.desc }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--====================  End of project slider area  ====================-->
</template>

<script>
import payforu from "../../data/payforu.json";
export default {
  data() {
    return {
      payforu,
      swiperOption: {
        speed: 3000,
        loop: true,
        slideChange: 10000,
        delay: 5000,
        autoplay: true,
        slidesPerView: 3,
        spaceBetween: 30
      }
    };
  }
};
</script>

<style lang="scss">
</style>