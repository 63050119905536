<template>
  <!--====================  project slider area ====================-->
  <div class="project-slider-area" style="padding-top: 10px;">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="latest-project-slider">
            <div class="latest-project-slider__container-area">
              <div class="swiper-container latest-project-slider__container">
                <div class="swiper-wrapper latest-project-slider__wrapper">
                  <swiper :options="swiperOption">
                    <div
                      class="swiper-slide latest-project-slider__single-slide"
                      v-for="slider in data"
                      :key="slider.id"
                    >
                      <div class="row row-30 align-items-center">
                        <div class="image">
                          <a :href="slider.link"
                            ><img
                              style="width: 95%;padding: 10px;"
                              :src="slider.image"
                              class="img-fluid"
                              alt="thumbnail"
                          /></a>
                        </div>
                      </div>
                    </div>
                  </swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--====================  End of project slider area  ====================-->
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      swiperOption: {
        speed: 3000,
        loop: true,
        slideChange: 10000,
        delay: 5000,
        autoplay: true,
        slidesPerView: 3,
        spaceBetween: 30
      }
    };
  }
};
</script>

<style lang="scss">
</style>