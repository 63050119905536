<template>
  <div class="container" style="padding: 10px 10px;">
    <div
      class="login-form"
      style="padding: 10px;box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);"
    >
      <button @click="isOpen = !isOpen" style="width: -webkit-fill-available;">
        <div class="col-12 text-left">
          <div class="row">
            <i class="fa fa-sort-desc"></i>
            <h3 style="margin-left: 10px;">{{ question }}</h3>
          </div>
        </div>
      </button>
      <div class="col-12 center-block">
        <collapse-transition>
          <div v-show="isOpen">
            <div
              style="font-size: 15px;text-align:justify;"
              v-for="line in answer.split('|')"
              :key="line.id"
            >
              {{ line }}
            </div>
          </div>
        </collapse-transition>
      </div>
    </div>
  </div>
</template>

<script>
import { CollapseTransition } from "@ivanv/vue-collapse-transition";
export default {
  name: "Accordion",
  components: {
    CollapseTransition
  },
  props: ["question", "answer"],
  data() {
    return {
      isOpen: false
    };
  },

  methods: {}
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
</style>
