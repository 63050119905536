<template>
  <swiper :options="swiperOption">
    <!--=======  single slider item  =======-->
    <div
      class="swiper-slide hero-slider__single-item"
      :style="{ backgroundImage: `url(${item.image})` }"
      v-for="item in sliderData"
      :key="item.id"
    >
      <div class="hero-slider__content-wrapper">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div
                class="hero-slider__content col-md-3"
                :class="[item.addClass]"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--=======  End of single slider item  =======-->
  </swiper>
</template>

<script>
export default {
  props: ["sliderData"],
  data() {
    return {
      swiperOption: {
        speed: 1000,
        delay: 3000,
        loop: true,
        effect: "fade",
        autoplay: {
          delay: 6000,
          disableOnInteraction: false
        },
        spaceBetween: 0,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".ht-swiper-button-next",
          prevEl: ".ht-swiper-button-prev"
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.hero-slider__wrapper .swiper-slide-active .hero-slider__content > :nth-child {
  animation-delay: 3s;
}
.hero-slider__content > * {
  animation-duration: 1s;
  animation-delay: 3s;
}

.hero-slider__content {
  height: 400px;
  width: -webkit-fill-available;
}

.hero-slider__text {
  font-size: 20px;
  text-align: justify;
  line-height: 1.8;
  color: #ffffff;
  margin-bottom: 50px;
  width: 70%;
}
.hero-slider__btn {
  font-size: 18px;
}
.swiper-wrapper {
  padding-top: 0px;
  height: 300px;
  padding: 0px !important;
}
.swiper-slide .hero-slider__single-item {
  padding-top: 0px;
}

.swiper-container-android .swiper-slide .swiper-wrapper {
  transform: translate3d(0px, 0, 0);
  padding-top: 0px;
}
.swiper-container {
  padding-top: 0px;
  margin-top: -10px !important;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: auto;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
</style>